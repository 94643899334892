import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroVideo from "../../components/hero/herovideo"
import RightCta from "../../components/cta/right-bordered/cta"
import ctaBackground from "../../images/sign-up/ctaBackground.svg"
import Savings from "../../components/savings/savings"

import "../../styles/healthy-heart-tips.css"
import cookingDesktop from "../../images/healthy-heart-tips/man-woman-cooking.png"
import cookingMobile from "../../images/healthy-heart-tips/man-woman-cooking-mobile.png"
import activeManDesktop from "../../images/healthy-heart-tips/stayActive.png"
import activeManMobile from "../../images/healthy-heart-tips/stayActiveMobile.png"

import activeLadyMobile from "../../images/healthy-heart-tips/1.3_543x300_Exercise_Mobile_2X.png"
import gradientOne from "../../images/healthy-heart-tips/gradientOne.png"
import gradientTwo from "../../images/healthy-heart-tips/gradientTwo.png"
import blockColumnImageOne from "../../images/healthy-heart-tips/block-column-image-one.png"
import blockColumnImageTwo from "../../images/healthy-heart-tips/block-column-image-two.png"
import blockColumnImageThree from "../../images/healthy-heart-tips/block-column-image-three.png"
import blockColumnImageFour from "../../images/healthy-heart-tips/block-column-image-four.png"
import blockColumnImageFive from "../../images/healthy-heart-tips/block-column-image-five.png"
import blockColumnImageSix from "../../images/healthy-heart-tips/block-column-image-six.png"
import blockColumnImageSeven from "../../images/healthy-heart-tips/block-column-image-seven.png"
import capsule from "../../images/healthy-heart-tips/capsule.png"
import capsuleMobile from "../../images/healthy-heart-tips/capsuleMobile.png"
import withDoctorMobile from "../../images/healthy-heart-tips/1.3_543x300_Doctor_Patient_Mobile_2X.png"
import glassHeart from "../../images/healthy-heart-tips/crystalHeart-main.png"

export default () => (
  <Layout>
    <SEO
      title="Explore Healthy Heart Tips"
      keywords="healthy heart tips, heart tips, heart health tips"
      description="Diet, exercise, and other simple lifestyle changes are a big part of managing cardiovascular (CV) risk. Learn how you can add heart-healthy habits to your routine. Please see Indications and Important Safety Information."
    />

    {/* <HeroVideo
				ctaBackground = {ctaBackground}
				colOne="5"
				colTwo="7"
        heroCustomClass={"hero-custom-class"}
				whiteborder="whiteborder"
        imageCustomClass={"image-custom-class"}
        image={heartLadyDesktop}
        alt="Doctor handing over the clear VASCEPA® (icosapent ethyl) heart"
				backgroundColor = "#E7E8EA"
        vimeoID = "<iframe src='https://player.vimeo.com/video/400401061' width='640' height='640' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>"
        animationA={"animationA"}
        animationB={"animationB"}
				headline="Add heart-healthy habits to your routine"
				content="Diet and exercise are a big part of managing your CV risk. Even simple, heart-healthy changes can make a difference. Choose a few tips below to add to your routine today."
        headlineColor = "#4D4D4D"
				/> */}
    <div className="cta-outer-hero mobile-auto-height">
      <div className="block row mobile-auto-height">
        <div className="hero-custom-class col-md-5 col-lg-5 block-content whiteborder">
          <div className="block-headline">
            <h1 className="gradient-headline animationA">
              Add heart-healthy habits to your routine as a starting point for
              improved health
            </h1>
            <p
              style={{ color: "#4D4D4D" }}
              className="hero-divided-content animationB"
            >
              Diet and exercise are a big part of managing your CV risk. Even
              simple, heart-healthy changes can make a difference. Choose a few
              tips below to add to your routine today.
            </p>
          </div>
        </div>
        <div className="col-md-7 col-lg-7 block-image video-container">
          <img alt="A clear VASCEPA® (icosapent ethyl) heart" src={glassHeart} />
        </div>
      </div>
    </div>

    <div className="block row ">
      <div
        style={{ padding: "0px" }}
        className="mobile-250 mobile-height-200 order-md-2 col-md-5 block-content"
      >
        <img
          class="block-row-image healthy"
          src={cookingDesktop}
          alt="Happy couple cooking a healthy meal together to lower their cardiovascular risk"
        ></img>
        <img
          class="block-row-image healthy d-lg-none img-mobile"
          src={cookingMobile}
          alt="Happy couple cooking a healthy meal together to lower their cardiovascular risk"
        ></img>
      </div>
      <div className="col-md-7 order-md-1 block-content whiteborderRight">
        <img class="background-gradient full" src={gradientOne} alt=""></img>
        <div className="block-content-mobile-padding">
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginTop: "50px",
              marginBottom: "0%",
              // marginLeft: "50px"
            }}
          >
            Eat healthy.
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "28px",
              lineHeight: "30px",
              fontWeight: "300",
              zIndex: "1",
              position: "relative",
              marginTop: "2%",
              marginBottom: "80px",
              paddingBottom: "30px",
              // marginLeft: "50px"
            }}
          >
            As with most things in life, eating healthy comes down to
            preparation. Here are some useful tips whether you're home or on the
            go.
          </p>
        </div>
      </div>
    </div>

    <div className="block row">
      <div className="col-md-4 block-column block-content">
        <img className="block-column-image" src={blockColumnImageOne} alt="Home icon" height="177px" width="135px" />
        <h4>At home</h4>
        <ul>
          <li>
            Double the batch of a heart-healthy recipe and freeze additional
            servings for future meals
          </li>
          <li>
            Combine leftovers&mdash;remember to keep tabs on portion sizes&mdash;to
            create new meals
          </li>
          <li>
            Chop vegetables and prepare fruit to make convenient snack packs
          </li>
        </ul>
      </div>
      <div className="col-md-4 block-column block-column-border-left block-column-mobile-border-top block-content">
        <img className="block-column-image" src={blockColumnImageTwo} alt="Bag icon"/>
        <h4>At work</h4>
        <ul>
          <li>Eat lunch away from your desk to avoid “mindless eating”</li>
          <li>
            Bring snacks with fiber and protein so you feel full longer, like
            unsalted nuts, Greek yogurt, or whole-grain crackers
          </li>
          <li>
            Replace cream with milk or plant-based milk in your coffee. Also try
            lowering your sugar intake
          </li>
        </ul>
      </div>
      <div className="col-md-4 block-column block-column-border-left block-column-mobile-border-top block-content">
        <img className="block-column-image" src={blockColumnImageThree} alt="Plate icon" height="177px" width="135px"/>
        <h4>At restaurants</h4>
        <ul>
          <li>
            Read the menu ahead of time. If you choose a less healthy option,
            plan to make healthier choices for the rest of the day
          </li>
          <li>
            Ask for healthy substitutions or for items (like sauces) on the side
          </li>
          <li>
            For big portions, request a to-go container and save half for
            tomorrow’s dinner
          </li>
        </ul>
      </div>
    </div>

    <div className="block row">
      <div
        style={{ padding: "0px" }}
        className="mobile-250 mobile-height-180 order-md-2 col-md-5 block-content light-gray"
      >
        <img width="100%"
          class="block-row-image mobile-hide"
          src={activeManDesktop}
          alt="Man playing basketball to help improve his heart health"
        ></img>
        <img width="100%"
          class="block-row-image d-lg-none height-auto bb-image "
          src={activeManMobile}
          alt="Man playing basketball to help improve his heart health"
        ></img>
      </div>
      <div className="col-md-7 order-md-1 block-content whiteborderRight">
        <img class="background-gradient full" src={gradientTwo} alt=""></img>
        <div className="block-content-mobile-padding">
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginTop: "50px",
              marginBottom: "0%",
              // marginLeft: "50px"
            }}
          >
            Stay active.
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "28px",
              lineHeight: "30px",
              fontWeight: "300",
              zIndex: "1",
              position: "relative",
              marginTop: "2%",
              marginBottom: "70px",
              paddingBottom: "30px",
              // marginLeft: "50px"
            }}
          >
            Here are some tips to set some goals and get up and go. Remember,
            always talk to your doctor first before starting a new exercise
            routine.
          </p>
        </div>
      </div>
    </div>

    <div className="block row">
      <div className="col-md-1 block-column block-content"></div>
      <div className="col-md-4 block-column block-content">
        <img className="block-column-image" src={blockColumnImageFour} alt="" />
        <h4>Set yourself up for success</h4>
        <ul>
          <li>
            Make clear goals by planning “what,” “when,” “where,” and “how long”
            for each activity
          </li>
          <li>
            Set your phone to buzz when you’ve reached your target steps for the
            day
          </li>
          <li>
            Remember: set reasonable expectations! Even a little more movement
            each day adds up
          </li>
        </ul>
      </div>
      <div className="col-md-1 block-column block-content"></div>
      <div className="col-md-1 block-column block-column-cross-vertical block-content"></div>
      <div className="col-md-4 block-column block-column-mobile-border-top overflow-visible block-content">
        <img className="block-column-image" src={blockColumnImageFive} alt=""/>
        <h4>Switch up your routine</h4>
        <ul>
          <li>Try a group exercise class or an online workout</li>
          <li>
            Add more movement to an everyday routine, like taking the stairs
            instead of the elevator
          </li>
          <li>
            Join an organization or charity that hosts active events like walks
            or runs
          </li>
        </ul>
      </div>
      <div className="col-md-1 block-column block-content"></div>
      <div className="col-md-1 block-column block-content"></div>
      <div className="col-md-4 block-column block-column-mobile-border-top block-content">
        <img className="block-column-image" src={blockColumnImageSix} alt=""/>
        <h4>Find more time</h4>
        <ul>
          <li>
            Work with your doctor to determine how many minutes of activity to
            aim for per week, and break that into manageable time periods (even
            if it’s only 10 minutes)
          </li>
          <li>
            Make TV time active! Walk in place, do yoga, or lift weights while
            you watch
          </li>
          <li>Exercise during the time of day when you have the most energy</li>
        </ul>
      </div>
      <div className="col-md-1 block-column block-content"></div>
      <div className="col-md-1 block-column block-column-cross-horizontal block-content"></div>
      <div className="col-md-4 block-column overflow-visible block-column-mobile-border-top block-content">
        <img className="block-column-image" src={blockColumnImageSeven} alt=""/>
        <h4>Taking prescribed medications</h4>
        <ul>
          <li>
            Schedule when you’ll take each of your medications, so you have a
            plan in place
          </li>
          <li>
            Work taking your medications into an established routine. For
            example, take them every morning after you brush your teeth
          </li>
          <li>Set reminders like a phone alert or fridge sticky note</li>
        </ul>
      </div>
      <div className="col-md-1 block-column block-content"></div>
    </div>

    <RightCta
      ctaBackground={ctaBackground}
      colOne="7"
      colTwo="5"
      image={capsule}
      mobileImage={capsuleMobile}
      alt="Vascepa capsule"
      headline="Now, find out how VASCEPA reduces your risk of a heart attack or stroke."
      cta={["VASCEPA benefits ", <span aria-hidden="true">&gt;</span>]}
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      url="/benefits"
      footnote="Capsule is not actual size."
      contentHeadline="right-now"
      mobileImageClass="img-fullwidth cap-mobile"
      imgSM="cap-mobile"
    />
     <div className="chatbot-spacer"></div>
  </Layout>
)
