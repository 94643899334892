import React from "react";
import { Link } from "gatsby"
import "./herovideo.scss"


const HeroVideo = (props) => {
    return (
      <div className="cta-outer-hero">
        <div className="block row">
          <div className={props.heroCustomClass +" col-md-"+props.colOne+" block-content "  +props.whiteborder}>
            {props.background ? <img className="background-gradient" src={props.background}></img> : null }
            <div className="block-headline">
              	<h2 className={"gradient-headline " + props.animationA} >{props.headline}</h2>
            	<p style={{color:props.headlineColor}} className={"hero-divided-content " + props.animationB}>{props.content}</p>
          	</div>

          </div>
          <div className={"col-md-"+props.colThree} ></div>
          <div className={"col-md-"+props.colTwo+" block-image video-container"}>


            <iframe src="https://player.vimeo.com/video/400401061?background=1&autoplay=1" 
              width="543" height="550" frameborder="0" 
              alt={props.alt}
              allow="autoplay; fullscreen" allowfullscreen
            >
            </iframe>


          </div>
        </div>
      </div>
    )
}


export default HeroVideo

HeroVideo.defaultProps = {
  animationA : ""
}
